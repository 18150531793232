import React from "react";
import { graphql } from "gatsby";

import Account from "../parts/Account";
import Layout from "../parts/Layout";
import { useMetadata } from "../queries/metadata";
import { CMSProvider, ThemesProvider } from "@happy-rabbit/gatsby-sanity-cms";

const pageConfigAccount = { slug: { current: '/account' }, pageSeo: { title: { en: 'Account' } } };

const AccountTemplate = props => {
  const {
    data,
    pageContext,
    path,
  } = props;

  const site = data.sites.nodes[0];
  const { lang } = pageContext;
  const siteMetadata = useMetadata();

  const {
    themeReferences: themes,
  } = site || {};

  return (
    <CMSProvider context={{ ...pageContext, site, siteMetadata, pageConfig: pageConfigAccount, path }}>
      <ThemesProvider themes={themes}>
        <Layout enableRunRoutes={true} lang={lang}>
          <Account />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  )
}

export const pageQuery = graphql`
query($siteId: String!) {
  sites: allSanitySite (filter: {_id: {eq: $siteId}}) {
    nodes {
      _id

      defaultCurrencyCode
      slug {
        current
      }
      themeReferences {
        ...SanityThemeFragment
      }
    }
  }
}
`;

export default AccountTemplate;

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import Modal from "../../components/Modal";
import { useIdentityStateContext } from "../../contexts/IdentityContext";
import SEO from "../SEO";
import { Button } from "@happy-rabbit/component-library";

const InvitationModal = props => {
  const {
    acceptInvite,
    token,
    onClose,
  } = props;

  const [choice, setChoice] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const { handleSubmit, register } = useForm({
    mode: "onBlur",
  });

  const onSubmit = data => {
    const { password, remember = true } = data;

    acceptInvite(token, password, remember)
      .then(status => {
        if (status === 'success') {
          onClose();
        } else {
          setStatus(status);
        }
      })
      .catch(err => {
        setStatus('error');
        setError(err);
      });
  };

  return (
    <Modal
      hasConfirm={false}
      hasClose={false}
      hideHeader={true}
      onClose={onClose}
      title={"Accept Invitation"}
    >
      <FaUserCircle className="text-6xl mx-auto mb-4" />

      {choice === 'accept' ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="account.acceptInvite.choose-a-password"
              defaultMessage="Choose a Password"
            />
            <input
              {...register('password')}
              className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
              required={true}
              type="password"
            />
          </label>

          {status === 'error' ? (
            <div className="text-depalmaRed">
              {error?.error_description ? error?.error_description : 'Something went wrong' }
            </div>
          ) : null}

          <div className="text-center mt-4">
            <Button type="submit" theme="success">
              <FormattedMessage
                id="account.acceptInvite.create-account"
                defaultMessage="Create Account"
              />
            </Button>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <h3 className="mb-4 averta-bold text-xl">
            <FormattedMessage
              id="account.acceptInvite.you-have-been-invited"
              defaultMessage="You have been invited to"
            />
            <br/>
            DePalma Workwear
          </h3>

          <p className="mb-4">
            <FormattedMessage
              id="account.acceptInvite.accept-the-invite"
              defaultMessage="Accept the invite to become a Member."
            />
          </p>

          <div className="flex justify-center">
            <div className="flex-1 mx-2">
              <Button onClick={() => setChoice('accept')} theme="success">
                <FormattedMessage
                  id="account.acceptInvite.accept"
                  defaultMessage="Accept"
                />
              </Button>
            </div>

            <div className="flex-1 mx-2">
              <Button onClick={onClose} theme="ghost">
                <FormattedMessage
                  id="account.acceptInvite.decline"
                  defaultMessage="Decline"
                />
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const Account = props => {
  const { param, acceptInvite, updateUser, user } = useIdentityStateContext();
  const [action, setAction] = useState(null);

  const { handleSubmit, register, setValue } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', user.user_metadata?.firstName);
      setValue('lastName', user.user_metadata?.lastName);
      setValue('email', user.email);
    }
  }, [user]);

  const onSubmit = data => {
    const { firstName, lastName, email } = data;
    const full_name = [firstName, lastName].filter(Boolean).join(' ');

    updateUser({ data: { firstName, lastName, full_name } })
      .catch(err => {
        setStatus('error');
        setError(err);
      });
  };

  useEffect(() => {
    if (param) {
      setAction(param.type);
    }
  }, [param]);

  return (
    <>
      <SEO />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl mb-4">
          <FormattedMessage
            id="account.account-settings"
            defaultMessage="Account Settings"
          />
        </h1>

        {user ? (
          <div className="max-w-lg mx-auto text-left">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="block flex-1 text-gray-700 text-sm mb-2">
                <FormattedMessage
                  id="account.email"
                  defaultMessage="Email"
                />
                <input
                  {...register('email')}
                  className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
                  disabled={true}
                  type="text"
                />
              </label>

              <div className="flex">
                <label className="block flex-1 text-gray-700 text-sm mb-2 mr-4">
                  <FormattedMessage
                    id="account.first-name"
                    defaultMessage="First Name"
                  />
                  <input
                    {...register('firstName')}
                    className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
                    type="text"
                  />
                </label>

                <label className="block flex-1 text-gray-700 text-sm mb-2">
                  <FormattedMessage
                    id="account.last-name"
                    defaultMessage="Last Name"
                  />
                  <input
                    {...register('lastName')}
                    className={clsx("block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline", { 'border-red-500': false })}
                    type="text"
                  />
                </label>
              </div>

              {status === 'error' ? (
                <div className="text-depalmaRed">
                  {error?.error_description ? error?.error_description : 'Something went wrong' }
                </div>
              ) : null}

              <div className="text-center mt-4">
                <Button type="submit" theme="success">
                  <FormattedMessage
                    id="account.save-changes"
                    defaultMessage="Save Changes"
                  />
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <p>
            <FormattedMessage
              id="account.not-logged-in"
              defaultMessage="You are not logged in."
            />
          </p>
        )}

        {action === 'invite' ? (
          <InvitationModal
            acceptInvite={acceptInvite}
            token={param.token}
            onClose={() => setAction(null)}
          />
        ) : null}
      </div>
    </>
  );
};

export default Account;
